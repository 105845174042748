// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  imageUrl: 'https://api1.topmai.app/api/image/uploadImageFile?id=',
  //imageUrl: 'https://topmai.app/api/image/uploadImageFile?id=',
  //imageUrl: 'http://localhost:3000/api/image/uploadImageFile?id=',
  //baseUrl: 'https://api2.topmai.app/api/',
  //baseUrl: 'https://topmai.app/api/',
  //baseUrl: 'http://localhost:3000/api/',

  baseUrl: 'https://topmaibackend2024-production.up.railway.app/api/',
  recaptcha: {
    sitekey: '6LdBSl8eAAAAAAzoJhIWe5Zn5k63RH4eggmi9olS',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyD6iN0suj3gyLL6m86kvvxtXWvtzfcMfuk',
    authDomain: 'topmaiinteraction.firebaseapp.com',
    databaseURL: 'https://topmaiinteraction-default-rtdb.firebaseio.com',
    projectId: 'topmaiinteraction',
    storageBucket: 'topmaiinteraction.appspot.com',
    messagingSenderId: '1096192370850',
    appId: '1:1096192370850:web:7ef3f8a0dd475158994ffb',
    measurementId: 'G-W0CJ7VG479'
  },
  onesignal:{
    appid: 'ddf6fab9-1f7d-4dd9-920d-e6cb280e62fb',
    restApiKey: 'ZWIzYjk5ZDctNDczYi00NzJmLTlmZWEtMGUwNzNiNjYzMmYw',

  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
